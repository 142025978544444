import React from 'react';
import Header from '../components/header';
import Btn_primary from '../components/btn_primary';
import { useHistory } from 'react-router-dom';
import { getDatabase, ref, set, onValue } from "firebase/database";


function Contact() {

  const [name, setname] = React.useState("");
  const [email, setemail] = React.useState("");
  const [message, setmessage] = React.useState("");
  const [betaList, setbetaList] = React.useState([]);

  const db = getDatabase();
  let history = useHistory();


    React.useEffect(() => {
        const betalists = ref(db, `betaList/`);
        onValue(betalists, (snapshot) => {
            if(snapshot.val()){
                setbetaList(snapshot.val());
            }
        });
    },[]);

    function submitBeta() {
      if(name != '' && email != '' ){
          var betalist = betaList;
          betalist.push({
              name: name,
              email: email,
              message: message
          })
          set(ref(db, 'betaList'),betalist);
          alert("Thank you! Our team will shortly contact you.");
          setname("");
          setemail("");
          setmessage("");
          // history.push('/');

      } else{
          alert('Please fill out the details.')
      }
     
    }


    return (
      
      <div>
        
        <Header />

        {/* <section id="contactus" className='w-full h-screen bg-contact bg-cover relative'>
            <div className='home-content lg:pl-24 pl-12 w-full h-full flex items-center contact-old'>
              <div className='lg:w-1/2 w-full lg:pl-40 px-5 flex flex-col text-white'>
                <p data-aos='fade-right' className='lg:text-sm text-xs mb-4 uppercase'>Let’s make it happen</p>
                <h1 data-aos='fade-right' className='lg:text-6xl text-2xl font-semibold'>Don’t hesitate to get in touch</h1>
                <div className='lg:w-48 w-32 bg-white rounded-3xl text-black lg:text-base text-xs py-2 font-semibold text-center hover:w-56 transition-all cursor-pointer lg:mt-16 mt-10 text-black flex justify-center'>
                  <div className='flex flex-row w-fit items-center'>
                    <p className=''>Ping us</p>
                    <div className='h-6 lg:border-l-2 border-l border-black mx-2'></div>
                    <a className='mr-1 text-xs' href="mailto:pixact.co@gmail.com" target="_blank"><i className="fas fa-envelope lg:text-lg text-base mr-1"></i></a>
                    <a className='' href="https://wa.me/+923052073889" target="_blank"><i className="fab fa-whatsapp lg:text-lg text-base mr-1"></i></a> 
                  </div>
                </div>
              </div>
              <div className='absolute h-24 lg:left-64 left-20 bottom-0 send-us'>
                <div className='h-full pl-3 border-l relative'>
                  <p className='lg:text-sm text-xs text-white uppercase'>Send us a Message</p>
                  <div className='absolute h-8 border-l top-0 left-0 see-work-scroll'></div>
                </div>
              </div>
            </div>
            <div className='w-0 h-full absolute top-0 left-0 bg-black bg-cover contact-form bottom-0 z-0 opacity-0'></div>
          </section> */}

        <div className='lg:pl-24 pl-12 w-full h-screen flex items-center bg-black'>
            <div className='form-width lg:px-40 px-5 flex flex-col text-white z-10'>
                <p data-aos='fade-right' className='text-sm mb-4 uppercase'>It will take seconds</p>
                <h1 data-aos='fade-right' data-aos-delay='100' className='lg:text-6xl text-3xl font-semibold'>Let’s do this</h1>
                {/* <p className='text-sm my-4 uppercase'>We’re excited to hear from you!</p> */}
                <form data-aos='fade-up' data-aos-delay='150' className='w-full lg:mt-5 mt-5' action="">
                  <div className='grid lg:grid-cols-2 grid-cols-1 gap-4 mt-4'>
                    <input value={name} onChange={(event) => setname(event.target.value)} className='w-full px-4 lg:py-3 py-2 border bg-transparent' placeholder="Name" type="text" />
                    <input value={email} onChange={(event) => setemail(event.target.value)} className='w-full px-4 lg:py-3 py-2 border bg-transparent' placeholder="Email" type="email" />
                  </div>
                  <textarea value={message} onChange={(event) => setmessage(event.target.value)} className='w-full px-4 lg:py-3 py-2 border bg-transparent mt-4' placeholder="Message" name="" id="" rows="5"></textarea>
                  <div onClick={submitBeta} data-aos='fade-left' data-aos-delay='200' className='w-full flex'>
                    <Btn_primary data-aos='fade-up' className='lg:mt-8 mt-5' name="Submit" />
                  </div>  
                </form>
              </div>
            </div>
      
      </div>

    );
  }
  
export default Contact;
