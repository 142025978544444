import React from 'react';
import Header from '../components/header';
import Opener from '../components/opener';
// import Work from '../components/work';
import Work_content from '../components/work_content';


function Works() {

    return (
      
      <div>
        
        <Header />
        <Work_content type="works" />
      
      </div>

    );
  }
  
export default Works;
