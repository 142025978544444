import React from 'react';
import Header from '../components/header';
import Service_content from '../components/services_content';


function Services() {

    return (
      
      <div>
        
        <Header />
        <Service_content />
      
      </div>

    );
  }
  
export default Services;
